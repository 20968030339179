import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDeleteProperty(ctx, id){
      return new Promise((resolve, reject) => {
        axios
          .delete('/property/v1/delete/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUpdateProperty(ctx, queryParams){
      var data = JSON.stringify(queryParams);
      
  
      return new Promise((resolve, reject) => {
        
          axios
          .post('property/v1/update_propertydate', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchUpdatePropertyFolder(ctx, queryParams){
      var data = JSON.stringify(queryParams);
      return new Promise((resolve, reject) => {
        
        axios
        .post('property/v1/update_propertfolder', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })

    },
    
    fetchgetProperty(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .get('/property/v1/getdetail/'+id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchgetActions(ctx,id){
      return new Promise((resolve, reject) => {
        axios
          .get('/property-action/v1/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProperties(ctx, queryParams){
      console.log('queryParams',queryParams);
      var data = JSON.stringify(queryParams);
      
      console.log('queryParams555',queryParams);
      return new Promise((resolve, reject) => {
        
          axios
          .post('cache-master/v1/property-filter', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchTemplates(ctx, id ){
   
   
      console.log('queryParams',queryParams);
      return new Promise((resolve, reject) => {
        
          axios
          .get('property/v1/get_templates/'+id )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })

    },
    fetchInvoices(ctx, queryParams) {
      
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/invoices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/invoice/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchClients() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/invoice/clients')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
