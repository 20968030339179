<template>
    <b-card-actions action-collapse title="Property Info Section">  
       
      <b-row>
         <b-col md="12">
            <b-form-group>
              <label>Owner of property[
                <feather-icon
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="formowner_show"
                                
                                
                                variant="outline-primary"
               icon="PlusCircleIcon" /> ]</label>
              
                <vue-autosuggest
                  ref="autocomplete"
                  v-model="owner_id"
                  :suggestions="suggestions"
                  :input-props="inputProps"
                  :section-configs="sectionConfigs"
                  :render-suggestion="renderSuggestion"
                  :get-suggestion-value="getSuggestionValue"
                  @input="fetchResults"
                />    
               
            </b-form-group>
        </b-col>
      </b-row>
       <b-row v-if="input.owner_id > 0">
         <b-col md="6">
            <b-form-group>
              <label>First name</label>
              <div>
              <b>{{ this.owner.owner_firstname}}</b>
              </div>
               
            </b-form-group>
        </b-col>
         <b-col md="6">
            <b-form-group>
              <label>Last name</label>
              <div>
              <b>{{ this.owner.owner_lastname}}</b>
              </div>
              
              
              
               
            </b-form-group>
        </b-col>
        <b-col md="6">
            <b-form-group>
              <label>Email</label>
              <div>
              <b>{{ this.owner.owner_email}}</b>
              </div>
            
              
               
            </b-form-group>
        </b-col>
        <b-col md="6">
            <b-form-group>
              <label>Phone No.</label>
              <div>
              <b>{{ this.owner.owner_phone}}</b>
              </div>
            
              
               
            </b-form-group>
        </b-col>
      </b-row>
       <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        v-model="isSidebarownerOpen"
        no-header
        right
        backdrop
        shadow
        ref="formOwner"
        class="side-bar-responsive"
        >
         <template >
             <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          
                <h5
                  
                  class="mb-0"
                >
                Add Owner
                </h5>
                <div>
                
                  <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="formowner_hide"
                  />
                </div>
          </div>
          <owners-formpopup v-on:childToOwner="formowner_hide" v-if="isSidebarownerOpen" />
   
           
         </template>      
    </b-sidebar>
    </b-card-actions>
</template>
<script>

import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,VBToggle,
  BFormCheckbox,BSidebar
} from 'bootstrap-vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import OwnersFormpopup  from '../../users/owner/owner-form/OwnersFormPopup.vue'
import router from '@/router'
export default {
  components: {
    BCardActions,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BSidebar,
    OwnersFormpopup
   // OwnersFormpopup
  },
  data() {
     return {
       input:{
         owner_id : null,
       },
       owner:{},
       owner_id : '',
       owId :router.currentRoute.params.ownerId,
       suggestions: [],
       isSidebarownerOpen:false,
       inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Owner of property',
        class: 'form-control',
        name: 'ajax',
        
        
       },
       sectionConfigs:{
          owner: {
            limit: 6,
            label: 'User',
            onSelected: selected => {
              this.input.owner_id = selected.item.id;
              this.owner.owner_firstname  = selected.item.owner_firstname;
                this.owner.owner_lastname  = selected.item.owner_lastname;
                this.owner.owner_email  = selected.item.owner_email;
                this.owner.owner_phone  = selected.item.owner_phone;
            
                this.emitToParent();
              
            },
          },
       
       }
     }
  },
   props: {
    parentData: {
      type: Object,
      
    },
    id:{
      type: [String, Number]
    }
  },
  directives: {
   
     'b-toggle': VBToggle,
      Ripple,
  },
  created() {
    if(this.id != undefined){
        this.getData();
      }
    if(this.owId != undefined){
      this.getOwner();

    }
  },
  methods: {
    async getOwner(){
       var user = await axios.get('users/v1/detail/'+this.owId);
       if(user.data.data.id){
         let usr = user.data.data;
         
        
          this.input.owner_id = this.owId
          this.owner_id = usr.firstname
          this.owner.owner_firstname  = usr.firstname;
          this.owner.owner_lastname  = usr.firstname;
          this.owner.owner_email  = usr.email;
          this.owner.owner_phone  = usr.phone;
          this.$emit('childToParent', this.input)
       }

    },
    formowner_hide(){
        this.isSidebarownerOpen = false;
    },
    formowner_show(){
      //formOwner
      this.isSidebarownerOpen = true;
    //this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
        //this.$refs.formOwner.show();
    },
    async getData(){
      var prop = await axios.get('property/v1/getdetail/'+this.id);
      var property = prop.data.data[0]
      if(property){
        this.input.owner_id = property.owner_id
        this.owner_id = property.owner_firstname
        this.owner.owner_firstname  = property.owner_firstname;
        this.owner.owner_lastname  = property.owner_lastname;
        this.owner.owner_email  = property.owner_email;
        this.owner.owner_phone  = property.owner_phone;
        console.log('property',property);
        this.$emit('childToParent', this.input)
      }
    
      //this.$emit('childToParent', this.input)
       /* 
       
        place_id:null,
  
       
       
       
       
        sale_id:null,
        
       
      
       */
      
    },
    emitToParent(event){
      this.$emit('childToParent', this.input)
     
    },
    fetchResults() {
      const { owner_id } = this
      console.log('query',owner_id);
      if(owner_id){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": owner_id
                });
            const placePromise = axios.post('autocomplete/v1/users',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestions = []
             
                console.log('values', values[0].data.data);
                var data =  values[0].data.data;
                this.suggestions.push({ name: 'owner', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestions = []
      }
    },
    
    filterResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestion(suggestion) {
        console.log('suggestion',suggestion);
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      console.log('suggestion',suggestion);
      
      return name === 'hotels' ? item.title : item.name
    },
  }
}
</script>