<template>
  <b-card title="" >
             <app-timeline v-if="logs" class="mt-3">

        <!-- 12 INVOICES HAVE BEEN PAID -->
        <template v-for="(log,key) in logs" >
        <app-timeline-item variant="info" :key="'logs-'+key" >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ log.title }}</h6>
            <small class="text-muted">{{ log.created_at | formatDate }}</small>
          </div>
        
          <b-media>
            <template #aside v-if="log.fullfilepath">
              <b-avatar :src="log.fullfilepath" />
            </template>
            <template #aside v-else>
              <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
            </template>
            <h6>{{ log.created_name }}</h6>
            <p class="mb-0" v-html="log.comment">
             
            </p>
          </b-media>
        </app-timeline-item>
        
        </template>
      </app-timeline>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
       
        class="mt-3"
      />
    </b-card>
</template>
<script>
import axios from '@axios'
import moment from 'moment'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckbox, BCard, BImg, BMedia, BAvatar,VBToggle,BSidebar,BBadge,BPagination
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
    BCardActions,
    BBadge,
    BCardText,
    BFormTextarea,
   
     ValidationProvider,
        ValidationObserver,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
  
    filterTags,
    formatDateToMonthShort,
    AppTimeline,
    AppTimelineItem, BCard, BImg, BMedia, BAvatar,BSidebar,
    BPagination,
    
    BButton
  },
    props: {
   
      id:{
        type: [String, Number]
      }
    },
      data() {
       return {
        
           userData: JSON.parse(localStorage.getItem('userData')),
        
          logs:[],
          currentPage:1,
          perPage:10,
          rows:0,
          
       } 
    },
    created(){
        this.getLogs();
    
    },
    filters: {
        formatDate: function(value){
        if (value) {
            return moment(String(value)).format('DD/MM/YYYY hh:mm')
        }else{
            return '';

        }

        }
    },
    watch:{
        currentPage:function(newpage,oldpage){
            this.currentPage = newpage;
            this.getLogs();

        }

    },
    methods: {
        async getLogs(){
            this.logs = [];
            const offset = this.perPage*( this.currentPage-1);
            let data = {
                types:'logs',
                perPage:this.perPage,
                Offsets:offset
            }
            var json = JSON.stringify(data);
            let logs = await  axios.post('property/v1/getComments/'+this.id,json);
            
            if(logs.data.success){
                this.logs = logs.data.data;
                this.rows = logs.data.total;

            }
            
        }
        
       
    }
}
</script>