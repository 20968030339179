<template>
    <b-card-actions action-collapse title="Price Section">  
       <b-form>
        <b-row>
         <b-col md="6">
            <b-form-group>
              <label>Selling Price / {{ input.salePriceSqm | getcomma}} per sqm</label>
              
                <b-form-input
                    v-model="input.salePrice"
                    placeholder="Selling Price"
                    @input="emitToParent"
                  />
               
            </b-form-group>
          </b-col>
           <b-col md="6">
            <b-form-group>
             <label>Rent Price / {{ input.rentPriceSqm | getcomma }} per sqm</label>
              
               <b-form-input
                    v-model="input.rentPrice"
                    placeholder="Rent Price"
                    
                     @input="emitToParent"
                  />
               
               
            </b-form-group>
          </b-col>
         
        </b-row>
       </b-form>
    </b-card-actions>
</template>
<script>

import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckbox
} from 'bootstrap-vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
export default {
  components: {
    BCardActions,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput
  },
  data() {
     return {
       input:{
         salePrice : null,
          salePriceSqm: null,
          rentPrice : null,
          rentPriceSqm: null,
       },
       sqm:0,
       //id:inputChange.id
       idata:{...this.inputData},
       
      
     }
  },
  props: {
    inputData: {
      type: Object,
      
    },
    id:{
      type: [String, Number]
    }
  },
  created(){
    console.log('inputdata',this.idata);
    if(this.id != undefined){
      this.getData();
    }
  },
   watch: {
    
    inputData: {
      deep: true,
      handler(value) {
        if(value.sqm > 0){
          this.sqm = value.sqm;
          this.emitToParent();
            
          
        }
      
        console.log('value',value);
      }
    }
   },
   filters: {
    getcomma: function(text){
      if(!text){
        return ''

      }
			 var nStr = text;
			nStr += '';
			var x = nStr.split('.');
			var x1 = x[0];
			var x2 = x.length > 1 ? '.' + x[1] : '';
			var rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 ;
		},
  },
  
  methods: {
    
     async getData(){
      var price = await axios.get('property/v1/getdetail/'+this.id);
    
      var prices = price.data.data[0];
      console.log('get_price',prices);
     
      if(prices){
        this.input.salePrice = prices.price_sale;
        this.input.salePriceSqm = prices.price_sale_sqm;
         this.input.rentPrice = prices.price_rent;
        this.input.rentPriceSqm = prices.price_rent_sqm;
        /*var sale = prices.filter(j => j.price_type_id == 2);
        var salesqm = prices.filter(j => j.price_type_id == 4);
        var rent = prices.filter(j => j.price_type_id == 1);
        var rentsqm = prices.filter(j => j.price_type_id == 3);
        

        if(sale){
          this.input.salePrice = sale[0].price;

        }
        if(salesqm){
          this.input.salePriceSqm = salesqm[0].price;

        }
        if(rent){
          this.input.rentPrice = rent[0].price;

        }
        if(rentsqm){
          this.input.rentPriceSqm = rentsqm[0].price;

        }*/
        console.log('sale',sale);
        //this.input.owner_id = property.owner_id
        //this.owner_id = property.owner_name
        this.$emit('childToParent', this.input)
      }
    
      //this.$emit('childToParent', this.input)
       /* 
       
        place_id:null,
  
       
       
       
       
        sale_id:null,
        
       
      
       */
      
    },
    emitToParent(){
      if(this.sqm > 0){
         if(this.input.salePrice > 0){
              let salesqm = this.input.salePrice/this.sqm;
             this.input.salePriceSqm = salesqm.toFixed();
            }
            if(this.input.rentPrice > 0){
               let rentPriceSqm = this.input.rentPrice/this.sqm;
               this.input.rentPriceSqm = rentPriceSqm.toFixed();

            }

        
      }
      this.$emit('childToParent', this.input)
     
    },
  }
}
</script>