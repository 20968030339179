<template>
   <b-card title="" >
             <validation-observer ref="commentRules" v-slot="{ handleSubmit }">
                <b-form  @submit.stop.prevent="handleSubmit(onSubmit)"  >
                  <b-row>
                    <b-col cols="12">
                      <b-form-group>
                     <validation-provider
                            #default="{ errors }"
                          rules="required"
                          name="comment"
                          >
                          <b-form-textarea
                              v-model="input_comment.comment"
                              placeholder="Comment"
                          />
                            <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button
                            variant="primary"
                            type="submit"
                            @click.prevent="validationFormComment"
                        >
                            Submit
                        </b-button>
                    </b-col>
                  </b-row>
                  
                </b-form>
                
             </validation-observer>
               <app-timeline v-if="comments" class="mt-3">

        <!-- 12 INVOICES HAVE BEEN PAID -->
        <template  >
        <app-timeline-item variant="info" v-for="(comment,key) in comments" :key="'comments-'+key" >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>{{ comment.title }} <b-badge variant="danger" style="cursor:pointer;" v-if="userData.id==comment.created_by" @click="deleteComment(comment.id)">Delete</b-badge></h6>
            <small class="text-muted">{{ comment.created_at | formatDate }}</small>
          </div>
        
          <b-media>
            <template #aside v-if="comment.fullfilepath">
              <b-avatar :src="comment.fullfilepath" />
            </template>
            <template #aside v-else>
              <b-avatar :src="require('@/assets/images/avatars/8-small.png')" />
            </template>
            <h6>{{ comment.created_name }}</h6>
            <p class="mb-0" v-html="comment.comment">
             
            </p>
          </b-media>
        </app-timeline-item>
        </template>
      </app-timeline>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
       
        class="mt-3"
      />
        </b-card>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckbox, BCard, BImg, BMedia, BAvatar,VBToggle,BSidebar,BBadge,BPagination
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import moment from 'moment'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
export default {
    components: {
     BCardActions,
    BBadge,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
     ValidationProvider,
        ValidationObserver,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    VuePerfectScrollbar,
    filterTags,
    formatDateToMonthShort,
    AppTimeline,
    AppTimelineItem, BCard, BImg, BMedia, BAvatar,BSidebar,
    BPagination,
    BButton
  },
    props: {
   
      id:{
        type: [String, Number]
      }
    },
      data() {
       return {
        
           userData: JSON.parse(localStorage.getItem('userData')),
          input_comment:{
            title:'Comment',
            comment:null,
            created_by:null,
            property_id:this.id,

          },
          comments:[],
        currentPage:1,
          perPage:10,
          rows:0,
          
       } 
    },
    created(){
        this.getComments();
    
    },
    filters: {
        formatDate: function(value){
        if (value) {
            return moment(String(value)).format('DD/MM/YYYY hh:mm')
        }else{
            return '';

        }

        }
    },
    watch:{
        currentPage:function(newpage,oldpage){
            this.currentPage = newpage;
            this.getComments();

        }

    },
     directives: {
   
     'b-toggle': VBToggle,
      Ripple,
  },
    methods: {
        async getComments(){
            this.comments = [];
            const offset = this.perPage*( this.currentPage-1);
            let data = {
                types:'comment',
                perPage:this.perPage,
                Offsets:offset
            }
            var json = JSON.stringify(data);
            let comments = await  axios.post('property/v1/getComments/'+this.id,json);
            
            if(comments.data.success){
                this.comments = comments.data.data;
                this.rows = comments.data.total;

            }
            console.log('comments',comments);

        },
         makeToast(variant = null,title,message) {
            this.$bvToast.toast(message, {
                title: title,
                variant,
                solid: true,
            })
        },
        async deleteComment(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Comment', {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                if(value === true){
                    axios.delete('property/v1/delteComment/'+id).then(res=>{
                        this.makeToast('success','Delete Comment','Completed');
                        this.currentPage=1;
                    this.getComments();
                    });
                    
                    //this.deleteData(id)
                }
                console.log('value',value);
                //this.deleteData(id)
                })
            
        
            },
        validationFormComment(){
            this.$refs.commentRules.validate().then(success => {
            
            this.input_comment.created_by = this.userData.id;
            var data = JSON.stringify(this.input_comment);
            if(success){
                console.log('data',data);
                axios.post('property/v1/add_comment',data)
                .then(res => { 
                console.log( 'res',res);
                 
                
                if(res.data.success){
                    this.makeToast('success','Add Comment',res.data.message);

                }else{
                    this.makeToast('danger','Add Comment',res.data.message);
                }
                
                this.$emit('childToClose');
               // this.formcomment_close();
                this.input_comment.comment = '';
                }).catch(error => {
                    this.makeToast('danger','Error 403',error);
                    console.log('error',error);
                });

            }
            });
        }
    }
}
</script>