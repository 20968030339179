<template>
 
<b-card-actions noActions>   
  <div class="d-flex justify-content-between align-items-right">
    <h4 class="mb-0">Property Info Section</h4>
    <b-button
      class="ms-auto"
      variant="outline-primary"
      :to="{ name: 'inventory-add', query: { propertyId: id } }"
    >
        {{ `Add Inventory List` }}
    </b-button>
  </div>
      
        <b-row>
         
          <b-col md="12">
          
            <b-form-group>
              <label>Property Pin</label>
              <validation-provider
              #default="{ errors }"
              rules="required"
              name="place_id"
              >
                <vue-autosuggest
                  ref="autocomplete"
                  name="place_id"
                  v-model="place_id"
                  :suggestions="suggestions"
                  :input-props="inputProps"
                  :section-configs="sectionConfigs"
                  :render-suggestion="renderSuggestion"
                  :get-suggestion-value="getSuggestionValue"
                  @input="fetchResults"
                />    
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Label</label>
             
                 <v-select
                    v-model="input.label_id"
                     @input="emitToParent"
                    
                    name="label_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="option"
                  />
             
            </b-form-group>
          </b-col>
           <b-col md="6">
            <label>Score</label>
            <b-form-group>
               <b-form-input
                    v-model="input.total_fbr_score"
                    @input="emitToParent"
                    placeholder="Score"
                  />
              
            </b-form-group>
          </b-col>
        
          <b-col md="3">
            <label>Address No.</label>
            <b-form-group>
              <b-form-input
                    v-model="input.addressNo"
                    @input="emitToParent"
                    placeholder="Address No."
                  />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label>Unit</label>
            <b-form-group>
              <b-form-input
                    v-model="input.units"
                    @input="emitToParent"
                    placeholder="Unit"
                  />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label>Floor</label>
            <b-form-group>
              <b-form-input
                    v-model="input.floors"
                    @input="emitToParent"
                    placeholder="Floor"
                  />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label>Type</label>
            <b-form-group>
             <b-form-input
                  v-model="input.type"
                  @input="emitToParent"
                  placeholder="Type"
                />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label>Area : sqm. (Marketing)</label>
            <validation-provider
              #default="{ errors }"
              rules="min:0"
              name="sqm"
              >
            <b-form-group>
              <b-form-input
                    v-model="input.sqm"
                    @input="emitToParent"
                    placeholder="sqm"
                  />
                   <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

            </validation-provider>
          </b-col>
          <b-col md="3">
            <label>Building</label>
            <b-form-group>
              <b-form-input
                    v-model="input.buildiing"
                    @input="emitToParent"
                    placeholder="Building"
                  />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label>Bedroom</label>
            <b-form-group>
              <b-form-spinbutton
                  v-model="input.bedroom"
                  @input="emitToParent"
                  min="0"
                  size="sm"
                  class="ml-75"
                  inline
                />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label>Bathroom</label>
            <b-form-group>
              <b-form-spinbutton
                  v-model="input.bathroom"
                  @input="emitToParent"
                  size="sm"
                  min="0"
                  class="ml-75"
                  inline
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>Key</label>
            <b-form-group>
              <b-form-input
                    v-model="input.key"
                    @input="emitToParent"
                    placeholder="Key"
                  />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>View</label>
            <b-form-group>
              <b-form-input
                    v-model="input.view"
                    @input="emitToParent"
                    placeholder="View"
                  />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>Direction</label>
            <b-form-group>
             
                 <v-select
                    v-model="input.direction_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    @input="emitToParent"
                    :options="option_deriction"
                  />
               
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>Furniture</label>
            <b-form-group>
             
                 <v-select
                    v-model="input.furniture_id"
                    @input="emitToParent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="furnitures"
                  />
               
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>Action</label>
            <b-form-group>
             
                 <v-select
                    v-model="input.action_id"
                    @input="emitToParent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="actions"
                  />
               
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label>Status</label>
            <b-form-group>
             
                 <v-select
                    v-model="input.status_id"
                     @input="emitToParent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="statuses"
                  />
               
            </b-form-group>
          </b-col>
           <b-col md="6">
            <b-form-group>
              <label>Expire date</label>
              
                <b-form-datepicker
                    id="expire_date"
                    v-model="input.expire_date"
                     reset-button
         close-button
                    class="mb-1"
                     @input="emitToParent"
                    />
            </b-form-group>
          </b-col>
           <b-col md="6">
            <b-form-group>
              <label>Updated Date</label>
              
             <b-form-datepicker
                    id="avaliable_date"
                    v-model="input.avaliable_date"
                     reset-button
      close-button
                    class="mb-1"
                     @input="emitToParent"
                    />
            </b-form-group>
          </b-col>
         
          
           <b-col md="6">
            <b-form-group>
              <label>Active</label>
              
                <b-form-checkbox
                 checked="true"
                  class="custom-control-primary"
                  name="is_active"
                  v-model="input.is_active"
                   @change="emitToParent"
                  switch
                />
                        
            </b-form-group>
          </b-col>  
          <b-col md="6">
            <b-form-group>
              <label>Featured</label>
              
                <b-form-checkbox
                 
                  class="custom-control-primary"
                  name="featured"
                  v-model="input.featured"
                  @change="emitToParent"
                  switch
                />
                        
            </b-form-group>
          </b-col>  
          <b-col md="12">
            <label>Property Comment.</label>
            <b-form-group>
              <b-form-textarea
                id="comment"
                v-model="input.comment"
                placeholder="comment"
                rows="3"
                v-on:keyup="emitToParent"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Responsible By</label>
              
                <vue-autosuggest
                  ref="autocomplete"
                  v-model="sale_id"
                  :suggestions="suggestions_sale"
                  :input-props="inputProps"
                  :section-configs="sectionConfigs"
                  :render-suggestion="renderSaleSuggestion"
                  :get-suggestion-value="getSaleSuggestionValue"
                  @input="fetchSaleResults"
                />    
              
            </b-form-group>
          </b-col>
           
           
         
      </b-row>

   
 </b-card-actions>
      
 
</template>

<script>
import { ref, onUnmounted, onMounted } from '@vue/composition-api'


import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckbox,BFormTextarea,BOverlay
} from 'bootstrap-vue'
import { BCard, BCardText, BAvatar } from 'bootstrap-vue'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

import axios from '@axios'
import { codeAjax } from './code'
import vSelect from 'vue-select'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import { BFormRating } from 'bootstrap-vue'
import { BFormDatepicker } from 'bootstrap-vue'
import furnitureStoreModule from '@/views/inventory-list/furnitureStoreModule'
import store from '@/store'

export default {
  
  inheritAttrs:true,
  components: {
    VueAutosuggest,
    BCardActions,
    BCard,
    BCardText,
    BAvatar,
    BFormRating,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormSpinbutton,
    BFormCheckbox,
    BFormTextarea,
    loading: true,
    BFormDatepicker,
   
  },
  
  watch:{
      parentData(){
      console.log('aavvv');
    }
  },
  data() {
    return {
    codeAjax,
      input:{
        comment:'',
        place_id:null,
        label_id:null,
        total_fbr_score:null,
        addressNo:null,
        units:null,
        floors:null,
        type:null,
        sqm:null,
        BOverlay,
        buildiing:null,
        bedroom:0,
        bathroom:1,
        key:null,
        view:null,
        direction_id:null,
        furniture_id:null,
        action_id:null,
        status_id:null,
        sale_id:null,
        is_active:true,
        featured:false,
         expire_date:'',
        avaliable_date:'',
       
      },
      dataP:this.parentData,

      name: '',
     place_id:'',
      featured:false,
      
      is_active:true,
     
      sale_id:'',
      
      
      type_id:'',
      
     
      
      
      datasuggest: [],
      filteredOptions: [],
      actions:[],
      expire_date:'',
      avaliable_date:'',
      limit: 10,
      selected: null,
      
      furnitures:[],
      results: [],
      timeout: null,
      selected: null,
      suggestions_sale:[],
   
      option: [{ id: 1, title: 'Best Rent' }, { id: 2, title: 'Best Buy' }, { id: 3, title: 'Reduced' }, { id: 4, title: 'Sold' }, { id:5, title:'Rented'}],
      option_deriction: 
        [
          { id: 1, title: 'North' }, 
          { id: 2, title: 'East' }, 
          { id: 3, title: 'South' }, 
          { id: 4, title: 'West' }, 
          { id:5, title:'North East'},
          { id:6, title:'North West'},
          { id:7, title:'South East'},
          { id:8, title:'South West'},
        ],
      statuses:[
          { id: 1, name: 'Available now' }, 
          { id: 2, name: 'Rented' }, 
          { id: 3, name: 'Sale with tenant' },
          { id: 5, name: 'Own Stay' },
          { id: 6, name: 'Not Sure' },
          { id: 7, name: 'Cannot contact' },
          { id: 8, name: 'Follow up' },
          { id: 4, name: 'Sold' }
      
         
      
      ],
      debounceMilliseconds: 250,
      usersUrl: 'https://jsonplaceholder.typicode.com/users',
      photosUrl: 'https://jsonplaceholder.typicode.com/photos',
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Property Pin',
        class: 'form-control',
        name: 'pin_id',
      },
      suggestions: [],
      sectionConfigs: {
        place: {
          limit: 6,
          label: 'Place',
          onSelected: selected => {
             this.input.place_id = selected.item.id;
              this.emitToParent();
            
          },
        },
        sale: {
          limit: 6,
          label: 'User',
          onSelected: selected => {
            this.input.sale_id = selected.item.id;
            this.emitToParent(); 
            
          },
        },
        
      },
    }
  },
  props: {
    parentData: {
      type: Object,
      
    },
    id:{
      type: [String, Number]
    }
  },
  computed:{
  
  },
 
  created() {
     console.log('id',this.id);
     //console.log('parentData',this.parentData);
    
     
   
     axios.get('furnituretype/v1/list')
      .then(res => { 
        console.log('res',res);
        this.furnitures = res.data.data; 
      })
      axios.get('property-action/v1/list')
      .then(res => { 
        console.log('res',res);
        
        this.actions = res.data.data; 
      })
      if(this.id != undefined){
        this.getData();
      }
  },
  
  
  methods: {
    async getData(){
      var prop = await axios.get('property/v1/getdetail/'+this.id);
      console.log('prop_a',prop);
      var property = prop.data.data[0]
      this.input.comment = property.comment;
      this.input.total_fbr_score = property.total_fbr_score;
     if( property.availble_date){
           this.input.avaliable_date = this.formatDate(property.availble_date);
        }
        if( property.expire_date){
           this.input.expire_date = this.formatDate(property.expire_date);
        }
      if(property.action_id){
        //this.actions 
        
        if(this.actions.length == 0){
         var actions =  await axios.get('property-action/v1/list');

          console.log('actions',actions);
          var action = actions.data.data.filter(j => j.id == property.action_id);
          if(action){
            this.input.action_id =action[0];
          }
        }else{
         
          var action = this.actions.filter(j => j.id == property.action_id);
           if(action){
              this.input.action_id =action[0];
            }

        }
       
        
      }
      
      if(property.label_id){
         var label = this.filterData(this.option,property.label_id,'id');
          if(label){
            this.input.label_id =label[0];
          }
        
      }
      if(property.direction_id){
        //this.actions 
        var direction = this.filterData(this.option_deriction,property.direction_id,'id');
       
        if(direction){
          this.input.direction_id =direction[0];
        }
        
      }
      if(property.furniture_id){
        //this.actions 
        if(this.furnitures.length == 0){
         var furnitures =  await axios.get('furnituretype/v1/list');

          console.log('furnitures',furnitures);
          var furniture = furnitures.data.data.filter(j => j.id == property.furniture_id);
          if(action){
            this.input.furniture_id =furniture[0];
          }
        }else{
          console.log('furnitures1',this.furnitures);
         var furniture = this.filterData(this.furnitures,property.furniture_id,'id');
       
          if(furniture){
            this.input.furniture_id =furniture[0];
          }

        }
        
        
      }
      if(property.status_id){
        //this.actions 
        var status = this.filterData(this.statuses,property.status_id,'id');
       
        if(status){
          this.input.status_id =status[0];
        }
        
      }
      this.input.rating = property.rating
      this.input.addressNo = property.address_no;
      this.input.units = property.units;
      this.input.floors = property.floors;
      this.input.type = property.type_name;
      this.input.sqm = property.sqm;
      this.input.buildiing = property.buildiing;
      this.input.bedroom = property.bedroom;
      this.input.bathroom = property.bathroom;
      this.input.key = property.note_roomkey;
      this.input.view = property.view_remark;
   
      
      this.input.is_active = property.is_active==1?true:false;
      this.input.featured = property.is_highlight==1?true:false;
      this.input.place_id = property.place_id;
      this.sale_id = property.sale_name;
      if(this.input.place_id){
        this.getrederPlace();

      }
      this.input.sale_id = property.sale_id;
     
      this.$emit('childToParent', this.input);
       /* 
       
        place_id:null,
  
       
       
       
       
        sale_id:null,
        
       
      
       */
      
    },
     formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
    getrederPlace(){

      
          var data = JSON.stringify({
              "keyword": "",
              "byID": this.input.place_id
          });
          
          const placePromise = axios.post('autocomplete/v1/place_byid',data)
        
          

          Promise.all([placePromise]).then(values => {
            let suggestion = {}
             suggestion.item = values[0].data.data[0]
             this.place_id =  suggestion.item.name;
           
          }).catch(error=>{
              console.log('error', error)
          })
    

    },
    filterData(data, text) {
     
      return data.filter(item => {
          
        if (item.id == text) {
         
          return item
        }
        return false
      })
    },
    propertyData(value){
       console.log('b',value);
    },
    getProperty(value){
      console.log('value',value);
      
     //this.input =  Object.assign({}, this.input, value );
      //console.log('input',  this.input)
    },
    emitToParent(event){
      
      this.$emit('childToParent', this.input)
     
    },
    fetchResults() {
      const { place_id } = this
      console.log('query',place_id);
      if(place_id){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": place_id
                });
            const placePromise = axios.post('autocomplete/v1/place',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestions = []
              this.selected = null
                console.log('values', values[0].data.data);
                var data =  values[0].data.data;
                this.suggestions.push({ name: 'place', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestions = []
      }
    },
    
    filterResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestion(suggestion) {
        console.log('suggestion',suggestion);
       
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      console.log('suggestion',suggestion);
      
      return name === 'hotels' ? item.title : item.name
    },
    fetchSaleResults() {
      const { sale_id } = this
      
      if(sale_id){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": sale_id
             });
            const placePromise = axios.post('autocomplete/v1/users',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestions_sale = []
              this.selected = null
                console.log('values', values[0].data.data);
                var data =  values[0].data.data;
                this.suggestions_sale.push({ name: 'sale', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestions_sale = []
      }
    },
    filterSaleResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSaleSuggestion(suggestion) {
      
      return suggestion.item.name
    },
    getSaleSuggestionValue(suggestion) {
      const { name, item } = suggestion
       
      return name === 'hotels' ? item.title : item.name
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
       
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
  },

  setup(props) {
    const FURNITURE_APP_STORE_MODULE_NAME = 'app-furniture'

    if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
      store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
      }
    });

    const inventoryData = ref([])
    const inventoryId = ref(null)

    const fetchInventoryData = () => {
      store
      .dispatch('app-furniture/getInventoryListData', {
        propertyId: props.id
      })
      .then((response) => {
        inventoryData.value = response.data.data
        inventoryId.value = response.data.data[0].id
      });
    }

    onMounted(() => {
      fetchInventoryData()
    })

      return {
        inventoryData,
        inventoryId,
        // fetchInventoryData,
      }
  },
}
</script>