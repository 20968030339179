<template>
    <b-card-actions action-collapse title="Amenity Section">  
      
        <b-row>
         <b-col md="12">
           
             <b-form-group label="Room">
                <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="input.facilityId"
                    @input="emitToParent"
                    :options="facilities"
                    text-field="name"
                    value-field="id"
                    name="facility_id"
                    class="demo-inline-spacing "
                />
                </b-form-group>
          </b-col>
          
        </b-row>
       
    </b-card-actions>
</template>
<script>

import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckboxGroup
} from 'bootstrap-vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
export default {
  components: {
    BCardActions,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormCheckboxGroup
  },
  data() {
     return {
       
       facilities:[],
       selected:[],
       //facility_id:[],
       input:{
         facilityId:[]
       }
      
     }
  },
  props: {
    parentData: {
      type: Object,
      
    },
    id:{
      type: [String, Number]
    }
  },
  created() {
      let queryParams = {
         perpage: 100,
        offset:0,
        type_code:'2',
        keyword:null
      }
     var data = JSON.stringify(queryParams);
      axios.post('facility/v1/lists',data)
      .then(res => { 
        
        this.facilities = res.data.data; 
        
      })
      if(this.id != undefined){
        this.getFacs();
      }
  },
  methods: {
    async getFacs(){
        let facs = await axios.get('property/v1/getfacilities/'+this.id);
        let fa = [];
        if(facs.data.data){
          facs.data.data.filter(item => {
            fa.push(item.id);
          });
        }
        this.input.facilityId = fa;
        this.$emit('childToParent', this.input)
        console.log('facs',facs);
        //api/property/v1/getfacilities
    },
     emitToParent(event){
       console.log('event',this.input);
      this.$emit('childToParent', this.input)
     
    },
  }
}
</script>