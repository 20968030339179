<template>
    <b-card-actions action-collapse title="Rent Section">  
       <b-form>
           <b-row>
         <b-col md="12">
            <b-form-group>
              <label>Rent Price</label>
              
                <b-form-input
                    v-model="input.rentPrice"
                    placeholder="Rent Price"
                     @input="emitToParent"
                  />
               
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Rent price per sqm.</label>
              
                <b-form-input
                    v-model="input.rentPriceSqm"
                    placeholder="Rent price per sqm."
                     @input="emitToParent"
                  />
               
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label>Rent Comment.</label>
            <b-form-group>
              <b-form-textarea
                id="rent_comment"
                placeholder="comment"
                v-model="input.rent_comment"
                
                rows="3"
                 @input="emitToParent"
              />
            </b-form-group>
          </b-col>
           <b-col md="12">
               <h4>Tenant</h4>
               <hr />
           
          </b-col>
          
          <b-col md="12">
            <b-form-group>
              <label>Present Tenant</label>
              
                 <vue-autosuggest
                  ref="autocomplete"
                  v-model="tenant_id"
                  :suggestions="suggestions"
                  :input-props="inputProps"
                  :section-configs="sectionConfigs"
                  :render-suggestion="renderSuggestion"
                  :get-suggestion-value="getSuggestionValue"
                  @input="fetchResults"
                />    
               
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label>Comment.</label>
            <b-form-group>
              <b-form-textarea
                id="tenant_comment"
                v-model="input.tenant_comment"
                placeholder="comment"
                  @input="emitToParent"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
       </b-form>
    </b-card-actions>
</template>
<script>

import { VueAutosuggest } from 'vue-autosuggest'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormSpinbutton,
  BFormCheckbox
} from 'bootstrap-vue'
import { BFormTextarea, BCardText } from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import axios from '@axios'
import { BFormDatepicker } from 'bootstrap-vue'
export default {
  components: {
    BCardActions,
    BCardText,
    BFormTextarea,
    VueAutosuggest,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormDatepicker
  },
  data() {
     return {
       input:{
          rentPrice : null,
          rentPriceSqm: null,
          rent_comment: null,
          expire_date:null,
        avaliable_date:null,
        tenant_comment:null,
        tenant_id:null
       },
      
       
       
       tenant_id:null,
       tenant_comment:null,
       suggestions: [],
       inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Tenant of property',
        class: 'form-control',
        name: 'ajax',
        
       },
       sectionConfigs:{
        tenant: {
          limit: 6,
          label: 'Place',
          onSelected: selected => {
            this.input.tenant_id = selected.item.id;
              this.emitToParent();
            
          },
        },
       
       }
      
     }
  },
  props:{
    id:{
      type: [String, Number]
    }
  },
  created() {
   
    if(this.id != undefined){
        this.getData();
        this.getPrice();
      }
  },
  methods: {
     async getData(){
       var prop = await axios.get('property/v1/getdetail/'+this.id);
        var property = prop.data.data[0];
        if( property.availble_date){
           this.input.avaliable_date = this.formatDate(property.availble_date);
        }
        if( property.expire_date){
           this.input.expire_date = this.formatDate(property.expire_date);
        }
        this.input.tenant_id = property.tenant_id;
        this.input.tenant_comment = property.tenant_comment;
        this.input.rent_comment = property.rent_comment;
        this.tenant_id = property.tenant_name;
       
      
        //tenant_id
        //tenant_comment
        //rent_comment
       
        console.log('rproperty',property);
     },
     formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    },
     async getPrice(){
      var price = await axios.get('property/v1/getprices/'+this.id);
      var prices = price.data.data
      
      if(prices.length > 0){
        var rent = prices.filter(j => j.price_type_id == 1);
        var rentsqm = prices.filter(j => j.price_type_id == 3);
        if(rent){
          this.input.rentPrice = rent[0].price;

        }
        if(rentsqm){
          this.input.rentPriceSqm = rentsqm[0].price;

        }
       
        this.$emit('childToParent', this.input)
      }
    
      
    },
    emitToParent(event){
      this.$emit('childToParent', this.input)
     
    },
      fetchResults() {
      const { tenant_id } = this
      console.log('query',tenant_id);
      if(tenant_id){

      
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            var data = JSON.stringify({
                "keyword": tenant_id
                });
            const placePromise = axios.post('autocomplete/v1/users',data)
          
            

            Promise.all([placePromise]).then(values => {
                this.suggestions = []
              this.selected = null
                console.log('values', values[0].data.data);
                var data =  values[0].data.data;
                this.suggestions.push({ name: 'tenant', data: data })
              
              // this.suggestions.push();
            }).catch(error=>{
                console.log('error', error)
            })
          }, this.debounceMilliseconds)
      }else{
          this.suggestions = []
      }
    },
    
    filterResults(data, text, field) {
      return data.filter(item => {
        if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
          return item[field]
        }
        return false
      }).sort()
    },
    renderSuggestion(suggestion) {
        
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      
      return name === 'hotels' ? item.title : item.name
    },
  }
}
</script>