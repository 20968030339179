<template>
  <validation-observer ref="simpleRules">
    
    <b-form   >
    <b-row>
        <b-col md="6">
          
        <form-info v-on:childToParent="onChildClick" :id="id" :parentData="propertyData" />   
        
        </b-col>
        <b-col md="6">
          <form-owner v-on:childToParent="onChildClick" :id="id" />   
          <form-sale v-on:childToParent="onSaleChange" :id="id" :inputData="inputChange"   /> 
        
           <form-facility v-on:childToParent="onChildClick" :id="id" />
           <form-comment v-if="id" :id="id" />
        </b-col>
        <b-col cols="12">
            
        </b-col>
        <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                     @click.prevent="validationForm"
                  >
                    Save
                  </b-button>
        </b-col>
    </b-row>

    </b-form>
  
  </validation-observer>
 
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'

import router from '@/router'
import FormInfo from './FormInfo.vue'
import FormOwner from './FormOwner.vue'
import FormSale from './FormSale.vue'
import FormRent from './FormRent.vue'
import FormFacility from './FormFacility.vue'
import FormComment from './FormComment.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import BCardActions from '../../../@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import store from '@/store'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import propertyStoreModule from '../propertiesStoreModule'
export default {
  components: {
    BCardActions,
    FormInfo,
    FormOwner,
    FormSale,
    FormRent,
    FormFacility,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    FormComment,
    BOverlay
  },//router.currentRoute.params.id
  data() {
    return {
      input : {},
      inputChange:{
      
      },
      id:router.currentRoute.params.id,
      userData: JSON.parse(localStorage.getItem('userData')),
      blur: '2px',
      show: false,
      variant: 'light',
      opacity: 0.85,
     
    }
  },
  created(){
    
     console.log('input',this.input);
     console.log('input',this.input);

  },
  directives: {
    Ripple,
  },
  setup() {
    const PROPERTY_APP_STORE_MODULE_NAME = 'app-property'
    if (!store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) store.registerModule(PROPERTY_APP_STORE_MODULE_NAME, propertyStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PROPERTY_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTY_APP_STORE_MODULE_NAME)
    })
    const propertyData = ref(null)
    const inputdata = ref(null)
    let id = router.currentRoute.params.id
    
   
    return {
      propertyData,
      inputdata
      
    }
  },
  
  
  methods: {
    Updateinput(){
      console.log('test');
    },
    onSaleChange(value){
      this.input =  Object.assign({}, this.input, value );
     

    },
    onChildClick(value){
      
     this.input =  Object.assign({}, this.input, value );
     
    this.inputChange = this.input;
    
    // this.inputChange.id = router.currentRoute.params.id
    //this.$emit('inputData', this.input)
      //console.log('parentinput',  this.input)
    },
    makeToast(variant = null,title,message) {
      this.$bvToast.toast(message, {
        title: title,
        variant,
        solid: true,
      })
    },
    validationForm(event) {
      
      
      this.$refs.simpleRules.validate().then(success => {
          console.log('abac',this.input);
        if (success) {
          this.show = true;
          this.input.created_by = this.userData.id;
           var data = JSON.stringify(this.input);
          //console.log('input',this.id);
          if(this.id != undefined){
            axios.put('property/v1/update/'+this.id,data)
            .then(res => { 
              console.log('res',res);
              if(!res.data.success){
                 this.show = false;
                this.makeToast('danger','Error 403',res.data.message);
              
                
              }else{
                 this.show = false;
                 this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                        title: `Done`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully created.`,
                      },
                });
                // this.$router.push('/property/lists', () => {
                //   this.$toast({
                //       component: ToastificationContent,
                //       position: 'top-right',
                //       props: {
                //         title: `Done`,
                //         icon: 'CoffeeIcon',
                //         variant: 'success',
                //         text: `You have successfully updated as .`,
                //       },
                //     })
                // });
                

              }
            // this.actions = res.data.data; 
            }).catch(error => {
              this.makeToast('danger','Error 403',res.data.message);
                console.log('error',error);
            })
          }else{
            console.log('created',data);
            axios.post('property/v1/add',data)
            .then(res => { 
              console.log('res',res);
              if(!res.data.success){
                this.makeToast('danger','Error 403',res.data.message);
              
                
              }else{
                // this.$toast({
                //   component: ToastificationContent,
                //   position: 'top-right',
                //   props: {
                //         title: `Done`,
                //         icon: 'CoffeeIcon',
                //         variant: 'success',
                //         text: `You have successfully created.`,
                //       },
                // });
                this.$router.push('/property/edit/'+res.data.data.id, () => {
                  this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Done`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `You have successfully created.`,
                      },
                    })
                });
                

              }
            // this.actions = res.data.data; 
            }).catch(error => {
              this.makeToast('danger','Error 403','error');
                console.log('error',error);
            })
          }
          
          
          
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>