import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getPropertyData(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                .get(`/property/v1/byid/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        addInventoryData(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/inventory/add`, params.formData, {
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'dataType':'json'
                    }
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        getFurnitureItemData() {
            return new Promise((resolve, reject) => {
                axios
                .get(`/property/v1/inventory/getItems`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        addRoomTypeData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/inventory/roomType/add`, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        getRoomTypeData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/inventory/roomType`, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        updateRoomTypeData(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/inventory/roomType/update`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        deleteRoomType(ctx, id){
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/property/v1/inventory/roomType/delete/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        uplodeImageData(ctx, params){
            return new Promise((resolve, reject) => {
                axios
                    .post(`/property/v1/inventory/uploadImages`, params.formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        removeImageData(ctx, params){
            return new Promise((resolve, reject) => {
                axios
                    .post(`/property/v1/inventory/removeImage`, params)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        getInventoryListData(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/inventory/getInventory`, data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        updateInventoryListData(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/inventory/update`, params.formData, {
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'dataType':'json'
                    }
                })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        getInventoryListPrintData(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                .post(`/property/v1/listsprint/get/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        deleteInventoryListData(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                  .delete(`/property/v1/inventory/delete/${id}`)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
            })
        }
    }
}